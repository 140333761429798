import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    messageStatus: null,
    users: [],
    form: {
        email: "",
        excludedIds: [],
        id_1c: "",
        ids: [],
        inn: "",
        kpp: "",
        limit: null,
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    companies: [],
    isShowModalUser: false,
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    users: state => state.users,
    form: state => state.form,
    companies: state => state.companies,
    isShowModalUser: state => state.isShowModalUser,
}

const actions = {
    getCompanies: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        let isAuthenticated = store.getters['auth/isAuthenticated']
        if (!isAuthenticated) {
            return 
        }
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/company", getters.form, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCompanies", response.data)
                    console.log(response.data)
                    resolve(response)
                })
        })
    },
    getUsersByCompany: ({commit}, company_id) => {
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/user_link_company/" + company_id)
                .then(response => {
                    commit("setMessage", 'get users by company success')
                    resolve(response)
                })
        })
    },
    deleteUser: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/user_link_company/" + id)
                .then(response => {
                    commit("setMessage", 'user deleted')
                    resolve(response)
                })
        })
    },
    createUser: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/create", payload)
                .then(response => {
                    commit("setMessage", 'user created')
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setCompanies: (state, val) => {
        val.sort((a, b) => Number(a.id_1c) - Number(b.id_1c))
        // val.sort((a, b) => Number(a.id_1c.replace(/[^0-9]/g,"")) - Number(b.id_1c.replace(/[^0-9]/g,"")))
        state.companies = val
    },
    updateForm: (state, val) => {
        state.form = val
    },
    setShowModalUser: (state, val) => {
        state.isShowModalUser = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
