<template>
    <div class="modal-iframe basket_not_empty_region_modal">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <h3>Ваша корзина не пуста. При смене региона корзина очистится</h3>
                        <div class="form" name="callback-form-popup">
                            <div class="form-fieldset form-fieldset--medium">
                                <div class="form-group">
                                    <div class="form-item buttons_block">
                                        <button class="button button--default" @click="close">Отмена</button>
                                        <button class="button button--primary" @click="confirm">Продолжить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
			order: 'basket/order',
            elementChangeRegion: 'app/elementChangeRegion',
		}),
	},
    methods: {
        ...Vuex.mapMutations({
            setShowModalBasketNotEmptyRegion: 'app/setShowModalBasketNotEmptyRegion',
            setCity:'auth/setCity',
            setCityName: 'auth/setCityName',
            setOrder: 'basket/setOrder',
            setChangeRegion: 'app/setChangeRegion',
        }),
        ...Vuex.mapActions({
            
        }),
        close() {
            this.setShowModalBasketNotEmptyRegion(false)
        },
        confirm() {
            localStorage.setItem('mayakRegionObject', JSON.stringify({city: this.elementChangeRegion.name, region: this.elementChangeRegion.region, utm_phone: ''}))
            this.choosenCity = this.elementChangeRegion.name
            this.setCityName(this.elementChangeRegion.name)
            this.setCity(this.elementChangeRegion.region)
            localStorage.removeItem("mayakOrderId")
            this.setOrder(
                {
                    id: null,
                    positions: [],
                    
                    account_type: null,
                    location: null,
                    postcode: null,

                    delivery_type: null,
                    pay_type: null,

                    organization_name: null,
                    organization_address: null,
                    inn: null,
                    kpp: null,

                    fio: null,
                    email:null,
                    phone: null,
                    comment: null,
                }
            )
            this.setChangeRegion({})
            this.close()
        },
    }
}
</script>
<style lang="scss">
    .basket_not_empty_region_modal{
        .buttons_block{
            display: flex;
            gap: 20px;

            button{
                width: 100%;
            }
        }
    }
</style>