<template>
    <section>
        <div class="gallery_slider_section">
            <swiper :options="swiperOption" ref="swiperThumbs">
                <swiper-slide v-for="image, idx in listImages" :key="idx" :asd="widthSlides[idx] ? 'width:' + widthSlides[idx] + 'px !important' : ''"> 
                    <img class="swiper_slider_image_coating" :src="image" alt="" :style="'height: 530px'">
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#CCCCCC" width="17" height="32" viewBox="0 0 8 8">
                <path d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z"/>
                </svg>
                <span class="sr-only">Предыдущий</span>
            </div>
            <div class="swiper-button-next">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#CCCCCC" width="17" height="32" viewBox="0 0 8 8">
                <path d="M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z"/>
                </svg>
                <span class="sr-only">Следующий</span>
            </div>
        </div>
    </section>
</template>
<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
    props: {
        listImages: {
            type: Array,
            default: () => []
        },
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOption: {
                spaceBetween: 20,
                slidesPerView: 'auto',
                slideToClickedSlide: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            },
            products: [],
            heightImage: '',
            widthSlides: [],
        }
    },
    computed: {
        paramsCoatingId() {
            return this.$route.params.id
        },
    },
    watch: {
        paramsCoatingId() {
            this.heightImage = ''
            setTimeout(() => {
                this.setWidthToSlides()
            }, 1000)
        }
    },
    mounted() {
        setTimeout(() => {
            this.setWidthToSlides()
        }, 1000)
    },
    methods: {
        setWidthToSlides() {
            var images = document.getElementsByClassName('swiper_slider_image_coating')
            var lowest_height = 0
            if (images) {
                for(let i = 0; i <= this.listImages.length - 1; i++) {
                    if (i == 0) {
                        lowest_height = images[0].clientHeight
                    }else if (i > 0 && images[i].clientHeight < lowest_height){
                        lowest_height = images[i].clientHeight
                    }
                }
            }
            this.heightImage = lowest_height + 'px'
            this.setWidthToImage()
        },
        setWidthToImage() {
            var images = document.getElementsByClassName('swiper_slider_image_coating')
            if (images) {
                for(let i = 0; i <= this.listImages.length - 1; i++) {
                    this.widthSlides.push(images[i].offsetWidth)
                }
            }
        },
    },
}
</script>
<style lang="scss">
    .gallery_slider_section{
        .swiper-slide {
            width: auto !important;
            img{
                width: auto;
            }
        }
    }
</style>
