<template>
    <div class="modal-iframe confirm_widget_modal">
            <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
                <div class="modal-container">
                    <div class="modal_header">
                        <p>Согласие на обработку персональных данных</p>
                    </div>
                  <!-- <button class="modal-close" @click="close">×</button> -->
                    <div class="state">
                        <div class="state-item">
                            <div class="text_block">
                                Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное 
                                согласие на обработку моих персональных данных ООО "РОСПРОМСТРОЙ" (ОГРН 1106829000728, ИНН 6829063243), зарегистрированным в соответствии с законодательством РФ по адресу: 
                                <br>
                                УЛ. МОСКОВСКАЯ, 1А, ТАМБОВ, ТАМБОВСКАЯ ОБЛАСТЬ, Россия, 392000 (далее по тексту - Оператор).
                                <br>
                                1. Согласие дается на обработку одной, нескольких или всех категорий персональных данных, не являющихся специальными или биометрическими, 
                                предоставляемых мною, которые могут включать:
                                <br>
                                <br>
                                - ФИО&nbsp;;
                                <br>
                                - Телефон;
                                <br>
                                - Список товаров выбор;
                                <br>- 
                                Ваш город.
                                <br>
                                <br>
                                2. Оператор может совершать следующие действия: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); 
                                извлечение; использование; блокирование; удаление; уничтожение. 
                                <br>
                                <br>
                                3. Способы обработки: как с использованием средств автоматизации, так и без их использования.
                                <br>
                                <br>
                                4. Цель обработки: предоставление мне услуг/работ, включая, направление в мой адрес уведомлений, касающихся предоставляемых услуг/работ, подготовка и направление 
                                ответов на мои запросы, направление в мой адрес информации о мероприятиях/товарах/услугах/работах Оператора.
                                <br>
                                <br>
                                5. В связи с тем, что Оператор может осуществлять обработку моих персональных данных посредством программы для ЭВМ «1С-Битрикс24», я даю свое согласие Оператору  
                                на осуществление соответствующего поручения ООО «1С-Битрикс», (ОГРН 5077746476209), зарегистрированному по адресу: 109544, г. Москва, б-р Энтузиастов, д. 2, эт.13, пом. 8-19.
                                <br>
                                <br>
                                6. Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на электронный адрес v.taskin@gkmayak.ru или направления 
                                по адресу УЛ. МОСКОВСКАЯ, 1А, ТАМБОВ, ТАМБОВСКАЯ ОБЛАСТЬ, Россия, 392000.<br><br>7. В случае отзыва мною согласия на обработку персональных данных Оператор вправе продолжить 
                                обработку персональных данных без моего согласия при наличии оснований, предусмотренных Федеральным законом №152-ФЗ «О персональных данных» от&nbsp;27.07.2006&nbsp;г.
                            </div>

                            <div class="buttons_block">
                                <button class="button button--primary" @click="confirm">Принимаю</button>
                                <button class="button button--default" @click="decline">Не принимаю</button>
                            </div>
                    </div>
                </div>

                <button @click="close" type="button" class="b24-window-close"></button>
            </div>
        </div>
    </div>
  </template>
  <script>
  import Vuex from 'vuex'
  import Functions from '@/utils/functions'
  
  export default {
    mixins: [Functions],
    methods: {
        ...Vuex.mapMutations({
            setShowSuccessCreateOrder: 'app/setShowSuccessCreateOrder',
        }),
        close() {
            this.$emit('closeConfirmWidget')
        },
        decline() {
            this.$emit('declineConfirmWidget')
        },
        confirm() {
            this.$emit('confirmConfirmWidget')
        },
      }
  }
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>
<style lang="scss">
    .confirm_widget_modal{
        .modal-container{
            padding: 0 !important;
            max-width: 600px !important;
            max-height: 650px;
            overflow-x: auto;

            .modal_header{
                border-bottom: 1px solid rgba(82, 92, 105, .11);
                
                p{
                    padding: 12px;
                    color: black;
                    font-size: 15px;
                }
            }

            .state{
                padding: 12px;

                .text_block{
                    color: black;
                    font-size: 15px;
                }

                .buttons_block{
                    margin-top: 12px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .button{
                        width: 100%;
                        border-radius: 4px;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 22px;
                    }
                }
            }

            .b24-window-close{
                position: absolute;
                top: 12px;
                right: 12px;
                width: 28px;
                height: 28px;
                border-radius: 100%;
                border: 0;
                -webkit-transition: opacity .3s;
                -o-transition: opacity .3s;
                transition: opacity .3s;
                opacity: .5;
                cursor: pointer;
                outline: 0;
                z-index: 25;
                background-color: #ffd110;
                background-color: rgba(237, 28, 36, 1);

                &:before{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 1px;
                    height: 15px;
                    background-color: #fff;
                    background-color: rgba(255, 255, 255, 1);
                    -webkit-transform: translate(-50%, -50%) rotate(45deg);
                    -ms-transform: translate(-50%, -50%) rotate(45deg);
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 1px;
                    height: 15px;
                    background-color: #fff;
                    background-color: rgba(255, 255, 255, 1);
                    -webkit-transform: translate(-50%, -50%) rotate(45deg);
                    -ms-transform: translate(-50%, -50%) rotate(45deg);
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
</style>