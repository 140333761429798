<template>
    <section>
        <div class="gallery_slider_section single_news">
            <swiper :options="swiperOption" ref="swiperThumbs">
                <swiper-slide  v-for="element, idx in elements" :key="idx" :asd="widthSlides[idx] ? 'width:' + widthSlides[idx] + 'px !important' : ''"> 
                    <img @click="pushToNews(element)" class="swiper_slider_image_coating" :src="element.image" alt="" :style="'height: ' + heightImage">
                    <p class="single_news_data">{{ formatDate(element.data) }}</p>
                    <p class="single_news_name">{{ element.name }}</p>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>
<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import functions from '@/utils/functions'

export default {
    props: {
        elements: {
            type: Array,
            default: () => []
        },
    },
    mixins: [functions],
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOption: {
                spaceBetween: 20,
                slidesPerView: 'auto',
                slideToClickedSlide: true,
            },
            heightImage: '',
            widthSlides: [],
        }
    },
    mounted() {
        setTimeout(() => {
            this.setWidthToSlides()
        }, 1000)
    },
    methods: {
        pushToNews(news) {
            if (news.link) {
                this.push(news.link)
            }else{
                this.push('aboutcompany/single/'+ news.id)
            }
        },
        formatDate(date) {
            var splited_date = ''
            var result_date = ''
            if (typeof date == 'string' && date.includes('-')) {
                splited_date = date.split('-')
                result_date = splited_date[2] + '.' + splited_date[1] + '.' + splited_date[0]
            }else{
                result_date = date
            }
            return result_date
        },
        setWidthToSlides() {
            var images = document.getElementsByClassName('swiper_slider_image_coating')
            var lowest_height = 0
            if (images) {
                for(let i = 0; i <= this.elements.length - 1; i++) {
                    if (i == 0) {
                        lowest_height = images[0].clientHeight
                    }else if (i > 0 && images[i].clientHeight < lowest_height){
                        lowest_height = images[i].clientHeight
                    }
                }
            }
            this.heightImage = lowest_height + 'px'
            this.setWidthToImage()
        },
        setWidthToImage() {
            var images = document.getElementsByClassName('swiper_slider_image_coating')
            if (images) {
                for(let i = 0; i <= this.elements.length - 1; i++) {
                    this.widthSlides.push(images[i].offsetWidth)
                }
            }
        },
    },
}
</script>
<style lang="scss">
    .gallery_slider_section{
        .swiper-slide {
            width: auto !important;
            img{
                width: auto;
            }
        }
    }
</style>
