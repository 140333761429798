import Http from "../../utils/http"
import Config from "../../config"

const state = {
    app: false,
    reviewModal: false,
    reviewObjForModal: {},
    hrefApp: '',
    appMail: false,
    objectForModal:{},
    message: '',
}

const getters = {
    app: state => state.app,
    reviewModal: state => state.reviewModal,
    reviewObjForModal: state => state.reviewObjForModal,
    hrefApp: state => state.hrefApp,
    appMail: state => state.appMail,
    objectForModal: state => state.objectForModal,
    message: state => state.message,
}

const actions = {
    sentApp: ({commit}, obj) => {
        return new Promise((resolve) => {
            var formData = new FormData()
            if (obj.email) {
                formData.append("email", obj.email)
            }
            formData.append("name", obj.name)
            formData.append("telephone", obj.telephone)
            if (obj.bannerTitle) {
                formData.append("bannerTitle", obj.bannerTitle)
            }
            if (obj.pageTitle) {
                formData.append("pageTitle", obj.pageTitle)
            }
            formData.append("type", obj.type)
            formData.append("message", obj.message)
            if (obj.file) {
                formData.append("file", obj.file)
            }else{
                formData.append("file", '')
            }
            Http.post(Config.apiUrl + '/v1/communication',
            formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
    sentAppBackCall: ({commit}, obj) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + '/v1/communication',obj)
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
    sendReviewOrClaim: ({commit}, obj) => {
        return new Promise((resolve) => {
            var formData = new FormData()
            formData.append("email", obj.email)
            formData.append("name", obj.name)
            formData.append("type", obj.type)
            formData.append("telephone", obj.telephone)
            formData.append("message", obj.message)
            if (obj.file) {
                formData.append("file", obj.file)
            }
            Http.post(Config.apiUrl + '/v1/review', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
}


const mutations = {
    setApp: (state, app) => {
        state.app = app
    },
    setReviewModal: (state, reviewModal) => {
        state.reviewModal = reviewModal
    },
    setReviewObjForModal: (state, reviewObjForModal) => {
        state.reviewObjForModal = reviewObjForModal
    },
    setHrefApp: (state, hrefApp) => {
        state.hrefApp = hrefApp
    },
    setAppMail: (state, appMail) => {
        state.appMail = appMail
    },
    setObjectForModal: (state, objectForModal) => {
        state.objectForModal = objectForModal
    },
    setMessage: (state, message) => {
        state.message = message
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
