<template>
    <div class="section--products--item">
        <div class="product" @click="toProduct($event, product)">
            <div class="product-header">
                <div 
                    v-if="false"
                    class="product-label" 
                >
                    Акция 
                </div>
                <div class="product-image">
                    <a class="product-link" v-if="!product.preview && parentProduct.links">
                        <img
                            :src="yandexCloud + parentProduct.links[0]" 
                            style="background-color: rgb(199, 199, 197);"
                            alt=""
                        />
                    </a>
                    <a class="product-link" v-if="product.preview">
                        <img
                            :src="yandexCloud + product.preview" 
                            style="background-color: rgb(199, 199, 197);"
                            alt=""
                        />
                    </a>
                    <a class="product-link" v-if="!product.preview && !parentProduct.links">
                        <img
                            src="@/assets/img/default_img_nomenclature.png"
                        />
                    </a>
                </div>
            </div>
            <div class="product-body">
                <div class="product-title">
                    <a class="product-link">{{ product.name }}</a>
                </div>
                <div v-if="regionCurrency[regionCode]" class="product-price">
                    от {{ product.price }} {{ regionCurrency[regionCode].currency }}/{{ product.base_unit_of_measure }}
                </div>
            </div>
            <div class="product-footer">
                <button class="button button--primary button--cart">
                    <svg class="icon" width="25" height="25" viewBox="0 0 25 25">
                        <use xlink:href="@/assets/img/icons.svg#cart"></use>
                    </svg>В корзину
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    props: {
        product: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            parentProduct: {},
        }
    },
    computed: {
        ...Vuex.mapGetters({
            newPosition: 'basket/newPosition',
            order: 'basket/order',
            yandexCloud: 'app/yandexCloud',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            region: 'auth/city',
        }),
        editNewPosition() {
            return Object.assign({}, this.newPosition)
        },
    },
    watch: {
        product() {
            this.getParentProduct()
        },
    },
    mounted() {
        this.getParentProduct()
    },
    methods: {
        ...Vuex.mapActions({
            addPosition: 'basket/addPosition',
            createPosition: 'basket/create',
            searchNavbar: 'catalog/searchNavbar',
        }),
        ...Vuex.mapMutations({
            clearNewPosition: 'basket/clearNewPosition',
        }),
        toProduct(ev, product) {
            if (ev.target.tagName == 'svg' || ev.target.tagName == 'BUTTON') {
                this.push('cardProduct/' + product.id)
            }else{
                this.push('cardProduct/' + product.id)
            }
        },
        getParentProduct() {
            this.searchNavbar({root_id: this.region, id_1cs: [this.product.parent_id], componentFrom: 'home/popular-single'})
                .then((response) => {
                    this.parentProduct = response.data[0]
                })
        },
    },
}
</script>
<style lang="scss">
    .section--products--item{
        .product-body{
            .product-title{
                min-height: 8rem;
            }
        }
    }
</style>